<template>
    <item-wrapper id="returns">
        <item-header :title="t('returns.title')" />
        <main>
            <p>
                {{ t('returns.intro') }}
            </p>
            <h3>{{ t('contactUs.sendUsAMessage') }}</h3>
            <div class="send-message-form">
                <a-form v-model="sendMessage" @submit="sendMessageFunc">
                    <a-form-item class="name"
                        v-bind="validateInfos.name">
                        <a-input v-model:value="sendMessage.name" allow-clear>
                            <template #prefix>{{ t('contactUs.name') }}</template>
                        </a-input>
                    </a-form-item>
                    <a-form-item class="email"
                        v-bind="validateInfos.email">
                        <a-input v-model:value="sendMessage.email" allow-clear>
                             <template #prefix>{{ t('user.email') }}</template>
                        </a-input>
                    </a-form-item>
                    <a-form-item class="order"
                                 v-bind="validateInfos.orderNumberOrImei">
                        <a-input v-model:value="sendMessage.orderNumberOrImei" :placeholder="t('returns.orderNumber')" allow-clear>
                        </a-input>
                    </a-form-item>
                    <a-form-item class="reason"
                                 v-bind="validateInfos.reason">
                        <!-- <span class="textarea-title">{{ t('returns.reasonForReturn') }}</span> -->
                        <a-textarea v-model:value="sendMessage.reason" :placeholder="t('returns.reasonForReturn')"/>
                    </a-form-item>
                    <a-button type="primary" html-type="submit" :loading="sendLoading">{{ t('returns.sendMessage') }}</a-button>
                </a-form>
            </div>
        </main>
    </item-wrapper>
</template>

<script lang="ts">
import type { ValidationRule } from 'ant-design-vue/lib/form/Form';
import importComponents from '@/utils/import-components';
import {
  defineComponent, reactive, ref,
} from 'vue';
import ItemHeader from '@/components/footerlink/ItemHeader.vue';
import ItemWrapper from '@/components/footerlink/ItemWrapper.vue';
import {
  Form, Input, Button,
} from 'ant-design-vue';

import { useForm } from '@ant-design-vue/use';
import { ReturnsInformationMessage } from '@/services/user';
import { Alert } from '@/components/Alert/index';
import { useI18n } from 'vue-i18n';

    type FormRules = Record<string, ValidationRule | ValidationRule[]>;

    type ValidatorRule = {
      field: string;
      fullField: string;
      trigger: string;
      type: string;
    }

    type ValidatorFunc = (rule: ValidationRule, value: string) => Promise<void>;

export default defineComponent({
  name: 'Returns',
  components: {
    ItemHeader,
    ItemWrapper,
    ...importComponents(
      Form,
      Form.Item,
      Input,
      Input.Password,
      Button,
      Input.TextArea,
    ),
  },
  setup() {
    const sendLoading = ref(false);
    const sendMessage = reactive({
      name: '',
      email: '',
      orderNumberOrImei: '',
      reason: '',
    });

    const { t } = useI18n();
    // 提示信息
    const returnInfos = (keys: string[]) => {
      const Info: Record<string, unknown> = {};
      keys.forEach((item) => {
        // 邮箱输入格式校验
        if (item === 'email') {
          const rejectFunc = (reason: string) => Promise.reject(new Error(reason));
          const regex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
          const email: ValidatorFunc = (rule, value) => {
            if (value === '') {
              return rejectFunc(t('contactUs.isRequired'));
            } if (!regex.test(value)) {
              return rejectFunc(t('user.emailMessage'));
            }
            return Promise.resolve();
          };
          Info[item] = [{ validator: email, required: true, trigger: 'change' }];
          return;
        }
        // 除了邮箱之外的校验
        Info[item] = [{
          required: true,
          message: t('contactUs.isRequired'),
        }];
      });
      return Info;
    };
    const sendMessageFormLabels: string[] = Object.keys(sendMessage);
    const sendMessageRules = reactive(returnInfos(sendMessageFormLabels));
    const {
      validate,
      validateInfos,
      resetFields,
    } = useForm(sendMessage, sendMessageRules);
    // 表单提交按钮
    const sendMessageFunc = async () => {
      try {
        const res = await validate();
        if (res) {
          const data = {
            email: res.email,
            message: res.reason,
            name: res.name,
            orderNumberOrImei: res.orderNumberOrImei,
          };
          // 请求接口
          try {
            sendLoading.value = true;
            const result = await ReturnsInformationMessage(data);
            if (result.success) {
              const modal = Alert.success(t('contactUs.sendSuccessful'));
              setTimeout(() => {
                modal.destroy();
              }, 2000);
              sendLoading.value = false;
              resetFields();
            } else {
              sendLoading.value = false;
              const modal = Alert.error(result.message);
              setTimeout(() => {
                modal.destroy();
              }, 2000);
            }
          } catch (e) {
            console.error(e);
          }
        }
      } catch (e) {
        console.error(e);
      }
    };
    return {
      validateInfos,
      sendMessage,
      sendMessageFunc,
      sendLoading,
      t,
    };
  },
});
</script>

<style lang="scss" scoped>
    @import "@/assets/styles/mixin.scss";
    @import '@/assets/styles/variables.scss';

    :deep(header) {
        h2 {
            margin-bottom: 40px;
        }
    }

    main {
        @include flex-center();
        flex-direction: column;

        p {
            max-width: 1018px;
            font-size: 18px;
            font-weight: 400;
            color: $font-color;
            line-height: 36px;
            margin-bottom: 102px;
            text-align: justify;

        }

        h3 {
            font-size: 20px;
            font-weight: 800;
            color: $font-color;
            line-height: 24px;
            margin-bottom: 40px;
        }

        .send-message-form {
            @include flex-center();
            width: 100%;
            margin-bottom: 73px;

            :deep(.ant-form) {
                .ant-form-item {
                    padding: 0;
                    width: 350px;
                    margin-bottom: 43px;

                    .ant-form-item-control-wrapper {
                        .ant-form-item-control {
                            line-height: 0;
                            position: relative;
                            .ant-form-item-children {
                                .ant-input-affix-wrapper{
                                    .ant-input-prefix{
                                        font-size: 14px;
                                        font-weight: 400;
                                        color: $font-color-weak;
                                        line-height: 17px;
                                        &::after {
                                            display: inline-block;
                                            margin-left: 4px;
                                            color: $theme-color;
                                            content: '*';
                                            font-size: 16px;
                                            // font-weight: 400;
                                        }
                                    }
                                    .ant-input {
                                        &::-webkit-input-placeholder {
                                            color: $font-color-weak;
                                        }
                                        &::-moz-input-placeholder {
                                            color: $font-color-weak;
                                        }
                                        &::-ms-input-placeholder {
                                            color: $font-color-weak;
                                        }
                                        width: 350px;
                                        height: 30px;
                                        padding: 0;
                                        line-height: 40px;
                                        font-size: 14px;
                                        padding: 0 6px;
                                    }
                                    &:hover {
                                        border-color: $theme-color;
                                    }
                                }
                                .ant-input-affix-wrapper-focused{
                                    border: 1px solid $theme-color;
                                    box-shadow: $box-shadow;
                                }
                            }

                            .ant-form-explain {
                                position: absolute;
                                top: 108%;
                                left: 0;
                                font-size: 12px;
                                font-weight: 400;
                                color: $theme-color;
                                line-height: 15px;
                            }
                        }
                    }

                    &.order .ant-form-item-control-wrapper .ant-form-item-control .ant-form-item-children .ant-input-affix-wrapper .ant-input{
                        padding-left: 0px;
                    }
                    &.reason {
                        margin-bottom: 45px;
                        .ant-form-item-control-wrapper {
                            .ant-form-item-control {
                                    position: relative;
                                .ant-form-item-children {
                                        display: block;
                                        // .textarea-title{
                                        //     position: absolute;
                                        //     top: 1px;
                                        //     left: 1px;
                                        //     z-index: 2;
                                        //     background: #fff;
                                        //     height: 30px;
                                        //     padding: 15px 0 0 11px;
                                        //     &::after {
                                        //         display: inline-block;
                                        //         margin-left: 4px;
                                        //         color: $theme-color;
                                        //         content: '*';
                                        //         font-size: 16px;
                                        //         // font-weight: 400;
                                        //     }
                                        // }
                                        .ant-input {
                                            width: 350px;
                                            height: 114px;
                                            line-height: 20px;
                                            resize: none;
                                            margin-bottom: 0;
                                            padding: 6px 11px;
                                            // text-indent: 140px;
                                            &::-webkit-scrollbar {
                                                display: none;
                                            }
                                            &:hover{
                                            border-color: $theme-color;
                                            }
                                            &:focus{
                                                border-color: $theme-color;
                                                box-shadow:0 0 0 2px rgb(230 0 44 / 20%);
                                            }
                                        }
                                }
                                .ant-form-explain {
                                    top: 103%;
                                }
                            }
                        }
                    }
                }

                textarea {
                    &::-webkit-input-placeholder {
                      color: $font-color-weak;
                    }
                    &::-moz-input-placeholder {
                      color: $font-color-weak;
                    }
                    &::-ms-input-placeholder {
                      color: $font-color-weak;
                    }
              }

                .ant-btn-primary {
                    background: $theme-color;
                    border-color: $theme-color;
                    width: 350px;
                    height: 44px;
                    border-radius: 4px;
                    font-size: 16px;
                    font-weight: bold;
                    color: #FFFFFF;
                    line-height: 20px;
                }
            }
        }
    }

</style>
