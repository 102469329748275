
import type { ValidationRule } from 'ant-design-vue/lib/form/Form';
import importComponents from '@/utils/import-components';
import {
  defineComponent, reactive, ref,
} from 'vue';
import ItemHeader from '@/components/footerlink/ItemHeader.vue';
import ItemWrapper from '@/components/footerlink/ItemWrapper.vue';
import {
  Form, Input, Button,
} from 'ant-design-vue';

import { useForm } from '@ant-design-vue/use';
import { ReturnsInformationMessage } from '@/services/user';
import { Alert } from '@/components/Alert/index';
import { useI18n } from 'vue-i18n';

    type FormRules = Record<string, ValidationRule | ValidationRule[]>;

    type ValidatorRule = {
      field: string;
      fullField: string;
      trigger: string;
      type: string;
    }

    type ValidatorFunc = (rule: ValidationRule, value: string) => Promise<void>;

export default defineComponent({
  name: 'Returns',
  components: {
    ItemHeader,
    ItemWrapper,
    ...importComponents(
      Form,
      Form.Item,
      Input,
      Input.Password,
      Button,
      Input.TextArea,
    ),
  },
  setup() {
    const sendLoading = ref(false);
    const sendMessage = reactive({
      name: '',
      email: '',
      orderNumberOrImei: '',
      reason: '',
    });

    const { t } = useI18n();
    // 提示信息
    const returnInfos = (keys: string[]) => {
      const Info: Record<string, unknown> = {};
      keys.forEach((item) => {
        // 邮箱输入格式校验
        if (item === 'email') {
          const rejectFunc = (reason: string) => Promise.reject(new Error(reason));
          const regex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
          const email: ValidatorFunc = (rule, value) => {
            if (value === '') {
              return rejectFunc(t('contactUs.isRequired'));
            } if (!regex.test(value)) {
              return rejectFunc(t('user.emailMessage'));
            }
            return Promise.resolve();
          };
          Info[item] = [{ validator: email, required: true, trigger: 'change' }];
          return;
        }
        // 除了邮箱之外的校验
        Info[item] = [{
          required: true,
          message: t('contactUs.isRequired'),
        }];
      });
      return Info;
    };
    const sendMessageFormLabels: string[] = Object.keys(sendMessage);
    const sendMessageRules = reactive(returnInfos(sendMessageFormLabels));
    const {
      validate,
      validateInfos,
      resetFields,
    } = useForm(sendMessage, sendMessageRules);
    // 表单提交按钮
    const sendMessageFunc = async () => {
      try {
        const res = await validate();
        if (res) {
          const data = {
            email: res.email,
            message: res.reason,
            name: res.name,
            orderNumberOrImei: res.orderNumberOrImei,
          };
          // 请求接口
          try {
            sendLoading.value = true;
            const result = await ReturnsInformationMessage(data);
            if (result.success) {
              const modal = Alert.success(t('contactUs.sendSuccessful'));
              setTimeout(() => {
                modal.destroy();
              }, 2000);
              sendLoading.value = false;
              resetFields();
            } else {
              sendLoading.value = false;
              const modal = Alert.error(result.message);
              setTimeout(() => {
                modal.destroy();
              }, 2000);
            }
          } catch (e) {
            console.error(e);
          }
        }
      } catch (e) {
        console.error(e);
      }
    };
    return {
      validateInfos,
      sendMessage,
      sendMessageFunc,
      sendLoading,
      t,
    };
  },
});
